import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DesktopTransactions: () =>
      import("./desktop/DesktopTransactions/DesktopTransactions.vue"),
    MobileTransactions: () =>
      import("./mobile/MobileTransactions/MobileTransactions.vue"),
  },
})
export default class Transactions extends Vue {}
